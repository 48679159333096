import { useEffect, useState } from 'react';
import { loginRequest } from "../../Auth/authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useAppDispatch, updateLoadingState, setActivityTemplates, useLazyFetchActivityTemplatesQuery, setUserProfile, useLazyFetchUserProfileQuery, updateDateRangeType, setErrorState } from '../../Redux';
import { useNavigate } from 'react-router-dom';
import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { DateRangeType } from '@fluentui/date-time-utilities/lib/dateValues/dateValues';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { Icon } from '@fluentui/react';

export const UserDetailsPersona = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  var isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [userDetails, setUserDetails] = useState<AuthenticationResult | null>(null);
  const [initials, setInitials] = useState<string | undefined>('');

  useEffect(() => {
    dispatch(updateLoadingState(true));
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then((response) => {
      setLoginDetails(response)
    }, (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        instance.acquireTokenPopup(loginRequest).then((response) => {
          setLoginDetails(response);
        });
      }
    });
  }, []);

  const setLoginDetails = (authDetails: AuthenticationResult) => {
    localStorage.setItem("accessToken", authDetails.accessToken);
    localStorage.setItem("userIdentifier", authDetails.uniqueId);
    setUserDetails(authDetails);
    var parts = authDetails.account?.name?.split(' ');
    var initials = parts?.map(p => p[0]).join("");
    if (initials && initials?.length > 2) {
      initials = initials?.slice(0, 2);
    }
    setInitials(initials);

    if (isAuthenticated) {
      fetchBookingComments();
      fetchUserProfile();
    }
  }

  const [fetchUserProfile, { data: userData, isSuccess, isError, error }] = useLazyFetchUserProfileQuery();
  const [fetchBookingComments, { data: bookingComments, isSuccess: isBookingQuerySuccess }] = useLazyFetchActivityTemplatesQuery();

  useEffect(() => {
    if (isBookingQuerySuccess) {
      dispatch(setActivityTemplates(bookingComments));
    }

    if (isSuccess) {
      dispatch(updateLoadingState(false));
      dispatch(setUserProfile(userData));
      dispatch(updateDateRangeType(userData?.bookingFormat === 0 ? DateRangeType.Week : DateRangeType.Month));
      navigate('/dashboard');
    }
    else if (isError) {
      dispatch(updateLoadingState(false));
      var err = error as FetchBaseQueryError;
      if (err.status == 404) {
        navigate('/nouserprofile');
      } else {
        dispatch(setErrorState());
        navigate('/error');
      }
    }
  }, [isSuccess, isError, isBookingQuerySuccess]);

  return (
    <div className='truncate text-primary lg:text-tertiary flex flex-row justify-start items-center h-10 lg:space-x-2 mx-1'>
      <span className='relative flex justify-center items-center p-1 rounded-full ring-1 lg:ring-2 ring-primary lg:ring-secondary w-8 h-8 select-none mx-1'>
        {
          initials && initials.length > 0 ?
            <span className='font-medium lg:font-light text-sm'>{initials}</span>
            : <Icon iconName='Contact' />
        }
      </span>
      <span className='hidden lg:relative lg:flex flex-col justify-start text-sm font-light max-w-[15rem]'>
        <span className='truncate'>{userDetails?.account?.name}</span>
        <span className='truncate'>{userDetails?.account?.username}</span>
      </span>
    </div>
  );
}