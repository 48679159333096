import { useState, useEffect } from 'react';
import { setLocale } from 'react-i18nify';
import { useAppDispatch, useAppSelector, setCalendarStrings } from '../../Redux'
import { NavigationButton } from '../Buttons/NavigationButton';
import { MaintenancePanelButton } from '../Buttons/MaintenancePanelButton';
import { ShowHiddenProjectsButton } from '../Buttons/ShowHiddenProjectsButton';
import { ChangelogNavigationButton } from '../Changelog/ChangelogNavigationButton';

export const UserActionsPanel = () => {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector((state) => state.userProfile.userProfile);
  const hiddenProjectsExist = useAppSelector((state) => state.projects.hiddenProjectsExist);
  const bookingComments = useAppSelector((state) => state.activityTemplates.activityTemplatesList);

  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(userProfile?.preferredLanguage.toString());

  useEffect(() => {
    if (userProfile?.id && !selectedLanguage) {
      var languageString = userProfile.preferredLanguage.toString();
      setSelectedLanguage(languageString);
      dispatch(setCalendarStrings(languageString === '1033'));
      setLocale(`Lang_${languageString}`);
    }
  }, [userProfile]);

  return (<>
    {
      userProfile?.id &&
      <div className='fixed flex flex-col w-full justify-start space-y-2 pt-4'>
        <NavigationButton buttonText='Home' icon='Home' navigationPath='/dashboard' />
        <NavigationButton buttonText='App_Button_Activity_Templates' icon='BulletedList' navigationPath='/activityTemplates' showCoachMark={bookingComments.length < 1} />
        <NavigationButton buttonText='App_Button_Download_Recording' icon='DownloadDocument' navigationPath='/download' />
        {hiddenProjectsExist && <ShowHiddenProjectsButton />}
        <ChangelogNavigationButton />
        {userProfile.isAdmin && <MaintenancePanelButton />}
        <NavigationButton
          openInNewWindow
          buttonText='App_Button_Help'
          icon='Help'
          navigationPath={userProfile?.preferredLanguage === 1031 ? process.env.REACT_APP_HELP_URL_DE : process.env.REACT_APP_HELP_URL_EN} />
      </div>
    }
  </>)
}