import { Icon } from "@fluentui/react";
import { translate, Translate } from "react-i18nify";
import { useState, useEffect } from 'react';
import TimeEntry from './TimeEntryComponent';
import { useAppDispatch, useAppSelector, updateLoadingState, useLazyFetchFaultyTimeRecordsQuery, usePutTimeRecordMutation, showNotificationWithTimeout } from '../../Redux';
import { ITimeEntry, IErrorResponse } from '../../Models';
import { groupBy } from '../../Utils';
import { XMarkIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";

const FailedTimeEntriesPanel = () => {

    const dispatch = useAppDispatch();
    const userProfile = useAppSelector((state) => state.userProfile.userProfile);
    const [showErrorPanel, setShowErrorPanel] = useState<boolean>(true);
    const [faultyTimeEntries, setFaultyTimeEntries] = useState<ITimeEntry[]>([]);
    const [keys, setKeys] = useState<string[]>([]);
    const [helpLink,] = useState<string>(process.env.REACT_APP_HELP_URL_EN);

    const [fetchFaultyTimeRecordsSilent, { data: lazyFaultyTimeRecords }] = useLazyFetchFaultyTimeRecordsQuery();
    const [updateTimeRecord, { isLoading: isLoadingPut, isSuccess: isPutSuccess, isError: isPutError, error: putError }] = usePutTimeRecordMutation();

    useEffect(() => {
        if (userProfile && userProfile.id > 0) {
            fetchFaultyTimeRecordsSilent();
        }
    }, [userProfile]);

    useEffect(() => {
        if (lazyFaultyTimeRecords) {
            setFaultyTimeEntries(lazyFaultyTimeRecords);

            let groupedTimeEntries: Map<string, ITimeEntry> = groupBy(lazyFaultyTimeRecords, (t: ITimeEntry) => { return t.workingPackage?.title });
            let keysArray: string[] = [];
            const size = groupedTimeEntries.size;
            const keys = groupedTimeEntries.keys();
            for (let i = 0; i < size; i++) {
                let key = keys.next().value;
                keysArray.push(key);
            }

            setKeys(keysArray);
        }
    }, [lazyFaultyTimeRecords]);

    useEffect(() => {
        if (isLoadingPut) {
            dispatch(updateLoadingState(true));
        }
    }, [isLoadingPut])


    useEffect(() => {
        if (isPutSuccess) {
            displayNotification('App_Notification_TimeRecording_Update_Success');
        } else if (isPutError && putError) {
            const parsedError: IErrorResponse = { ...putError };
            displayNotification(typeof parsedError.data == 'string' ? parsedError.data : 'Error', true);
        }

        dispatch(updateLoadingState(false));
    }, [isPutSuccess, isPutError, putError]);

    const displayNotification = (message: string, isError: boolean = false) => {
        dispatch(showNotificationWithTimeout({
            notificationId: `${Math.random()}`,
            notificationMessage: translate(message),
            isError: isError
        }));
    }

    const updateTimeEntry = async (timeEntry: ITimeEntry) => {
        return await updateTimeRecord(timeEntry);
    }

    const renderGroup = (key: string) => {
        let content = [];
        content.push(<p className='w-full px-2 py-1 rounded-t-sm border-b border-primary my-2 font-semibold bg-gradient-to-r from-secondary via-lightning to-coral' key={key}>{key}</p>);
        var filteredRecords = lazyFaultyTimeRecords?.filter(t => t.workingPackage?.title === key) ?? [];
        filteredRecords.map(t => content.push(<TimeEntry key={t.id} timeEntry={t} refreshTimeRecords={fetchFaultyTimeRecordsSilent} onUpdate={updateTimeEntry} />))

        return content;
    }

    return (<>
        {
            showErrorPanel && faultyTimeEntries && faultyTimeEntries?.length > 0 &&
            <div className="shadow-md mx-1 mb-8 bg-white flex flex-col p-3 rounded-md">
                <div className="w-full flex flex-row">
                    <div className="w-11/12 text-primary font-semibold">
                        <span className='text-red flex flex-row'><ExclamationCircleIcon className="stroke-red h-6 w-6 stroke-[2]" /> <Translate value='App_Info_Unresolved_Conflicts_Error' /></span>
                        <p>
                            <Translate value='App_Info_Unresolved_Conflicts_Activities_Not_Synced' />
                        </p>
                        <p>
                            <Translate value='App_Info_Unresolved_Conflicts_Deletion_Notice' />
                        </p>
                    </div>
                    <div className="w-1/12 flex justify-end">
                        <XMarkIcon className="h-5 w-5 stroke-[2]" onClick={() => setShowErrorPanel(false)} />
                    </div>
                </div>
                <div className="w-full my-2">
                    {
                        keys.map(k => renderGroup(k))
                    }
                </div>
                <div className="w-full mt-3 border-t">
                    <a href={helpLink} target="_blank" className='text-primary font-semibold underline'><Translate value='App_Info_Unresolved_Conflicts_Click_Here' /></a> <Translate value='App_Info_Unresolved_Conflicts_Info_Question' />
                    <a href="mailto:billing@metafinanz.de" className='text-primary font-semibold underline'><Translate value='App_Info_Contact_Billing' /></a> <Translate value='App_Info_Unresolved_Conflicts_Info_End' />
                </div>
            </div>
        }
    </>)
}

export default FailedTimeEntriesPanel;