import TimeEntryEditMode from "./TimeEntryEditComponent";
import TimeEntry from "./TimeEntryComponent";
import { ITimeEntry, IErrorResponse, IWorkPackage, IDailyBookedTime } from "../../Models";
import { useAppDispatch, useAppSelector, updateLoadingState, showNotificationWithTimeout, useFetchTimeRecordsQuery, usePostTimeRecordMutation, usePutTimeRecordMutation } from "../../Redux";
import { useEffect, useState } from "react";
import { Translate, translate } from "react-i18nify";
import { useLazyFetchTimeRecordsQuery, useLazyFetchTotalBookedHoursPerDayQuery } from "../../Redux/timeRecordsSlice";
import { DateTime } from "luxon";
import { Divider } from "..";

interface TimeEntrycontainerProps {
    timeEntries?: ITimeEntry[]
    workPackage: IWorkPackage
}

const TimeEntryContainer = (props: TimeEntrycontainerProps) => {
    const dispatch = useAppDispatch();
    const dateRange = useAppSelector((state) => state.calendar.dateRange);
    const selectedDate = useAppSelector((state) => state.calendar.selectedDate);
    const userProfile = useAppSelector((state) => state.userProfile.userProfile);
    const publicHolidays = useAppSelector((state) => state.publicHoliday.publicHolidays);

    const [emptyTimeEntry, setEmptyTimeEntry] = useState<ITimeEntry>({ id: undefined, comment: undefined, state: 1, on: new Date(), actualLength: 480, estimatedLength: 480 });
    const [timeEntries, setTimeEntries] = useState<ITimeEntry[]>([]);
    const [isInitialLoadDone, setIsInitialLoadDone] = useState<boolean>(false);

    const [addNewTimeRecord, { isLoading: isLoadingPost, isSuccess: isPostSuccess, isError: isPostError, error: postError }] = usePostTimeRecordMutation();
    const [updateTimeRecord, { isLoading: isLoadingPut, isSuccess: isPutSuccess, isError: isPutError, error: putError }] = usePutTimeRecordMutation();
    const { data: timeEntriesData, isLoading: isFetchLoading, isSuccess: isFetchSuccess } = useFetchTimeRecordsQuery({ dateRange, workPackageId: props.workPackage.id as number });
    const [fetchTimeRecordsSilent, { data: lazyTimeEntries }] = useLazyFetchTimeRecordsQuery();
    const [fetchBookedHours, { }] = useLazyFetchTotalBookedHoursPerDayQuery();

    useEffect(() => {
        if (isLoadingPost || isLoadingPut || isFetchLoading) {
            dispatch(updateLoadingState(true));
        }
    }, [isLoadingPost, isLoadingPut, isFetchLoading])

    useEffect(() => {
        if (isPostSuccess) {
            displayNotification('App_Notification_TimeRecording_Create_Success');
        } else if (isPostError && postError) {
            const parsedError: IErrorResponse = { ...postError };
            displayNotification(typeof parsedError.data == 'string' ? parsedError.data : 'Error', true);
        }

        dispatch(updateLoadingState(false));
    }, [isPostSuccess, isPostError, postError]);

    useEffect(() => {
        if (isPutSuccess) {
            displayNotification('App_Notification_TimeRecording_Update_Success');
        } else if (isPutError && putError) {
            const parsedError: IErrorResponse = { ...putError };
            displayNotification(typeof parsedError.data == 'string' ? parsedError.data : 'Error', true);
        }

        dispatch(updateLoadingState(false));
    }, [isPutSuccess, isPutError, putError]);

    useEffect(() => {
        if (isFetchSuccess) {
            dispatch(updateLoadingState(false));
            setIsInitialLoadDone(true);
        }
    }, [isFetchSuccess])

    useEffect(() => {
        setTimeEntries(timeEntriesData ?? []);
    }, [timeEntriesData])

    useEffect(() => {
        if (lazyTimeEntries && lazyTimeEntries.length > 0) {
            setTimeEntries(lazyTimeEntries);
        }
    }, [lazyTimeEntries])

    useEffect(() => {
        let tempEmptyTimeEntry = { ...emptyTimeEntry };
        tempEmptyTimeEntry.on = selectedDate;
        setEmptyTimeEntry(tempEmptyTimeEntry);
    }, [selectedDate])

    const displayNotification = (message: string, isError: boolean = false) => {
        dispatch(showNotificationWithTimeout({
            notificationId: `${Math.random()}`,
            notificationMessage: translate(message),
            isError: isError
        }));
    }

    const saveTimeEntry = async (timeEntry: ITimeEntry) => {
        timeEntry.workingPackage = props.workPackage;
        timeEntry.workingPackage_Id = props.workPackage.id;
        timeEntry.userProfile = userProfile;

        await addNewTimeRecord(timeEntry);

        if (userProfile?.isAutoIncrementEnabled) {
            // get existing bookings from the day of the booking until the end of the selected booking period
            let bookedHoursResult: IDailyBookedTime[] = await fetchBookedHours({ dateRange: [timeEntry.on, dateRange[1]] }).unwrap();
            const existingBookingOnTimeEntryDate = bookedHoursResult?.filter((t: IDailyBookedTime) => { return new Date(t.bookingDate).getDate() == new Date(timeEntry.on).getDate() });
            //calculate booked hours for the current booked day
            let bookedHours = existingBookingOnTimeEntryDate && existingBookingOnTimeEntryDate.length > 0 ? existingBookingOnTimeEntryDate[0].timeLength / 60 : 0;

            //if it's bigger than the threshold try and increment the day stating with tomorrow until the end of the selected booking period
            if (bookedHours >= userProfile.autoIncrementHoursThreshold) {
                const firstDayInRange = DateTime.fromJSDate(timeEntry.on).plus({ days: 1 });
                const lastDayInRange = DateTime.fromJSDate(dateRange[1]);
                let tempEmptyTimeEntry = { ...emptyTimeEntry };
                let iterationDate = firstDayInRange;
                while (iterationDate.day <= lastDayInRange.day && iterationDate.month == lastDayInRange.month) {
                    const isFreeDay = publicHolidays.filter((v) => { return new Date(v.date).toDateString() == iterationDate.toJSDate().toDateString() }).length > 0;
                    if (iterationDate.weekday <= 5 && (!isFreeDay || (isFreeDay && !userProfile.autoIncrementSkipHolidays))) {
                        const alreadyBooked = bookedHoursResult?.filter((t: IDailyBookedTime) => { return new Date(t.bookingDate).getDate() == iterationDate.toJSDate().getDate() });
                        if (alreadyBooked && alreadyBooked.length > 0) {
                            //if there are bookings on the current iterationDate and the ammount booked is lower than the threshold select the day
                            if (alreadyBooked[0].timeLength / 60 < userProfile.autoIncrementHoursThreshold) {
                                tempEmptyTimeEntry.on = iterationDate.toJSDate();
                                setEmptyTimeEntry(tempEmptyTimeEntry)
                                break;
                            }
                        } else {
                            // if there are no bookings on on the current iterationDate, select the day
                            tempEmptyTimeEntry.on = iterationDate.toJSDate();
                            setEmptyTimeEntry(tempEmptyTimeEntry)
                            break;
                        }
                    }

                    iterationDate = iterationDate.plus({ days: 1 });
                }
            }
        }
    }

    const updateTimeEntry = async (timeEntry: ITimeEntry) => {
        timeEntry.workingPackage = props.workPackage;
        timeEntry.workingPackage_Id = props.workPackage.id;
        timeEntry.userProfile = userProfile;

        return await updateTimeRecord(timeEntry);
    }

    const fetchTimeEntriesSilent = () => {
        fetchTimeRecordsSilent({ dateRange, workPackageId: props.workPackage.id as number });
    }

    const getTimePeriod = (): string => {
        var from = DateTime.fromJSDate(dateRange[0]);
        var until = DateTime.fromJSDate(dateRange[1]);
        return `${from.toFormat('dd LLL yyyy')} - ${until.toFormat('dd LLL yyyy')}`;
    }

    return (<>
        <div className={`w-full relative transition-all ease-in-out delay-150 min-h-[80px] bg-white flex flex-col space-y-1`}>
            <div className="w-full shadow-md p-3 rounded-md border-l-4 rounded-t-none">
                <TimeEntryEditMode timeEntry={emptyTimeEntry} onSave={saveTimeEntry} refreshTimeRecords={fetchTimeEntriesSilent} />
            </div>
            <div className="flex flex-col pt-4 ">
                <div className="w-full bg-primary text-tertiary h-10 px-2 text-sm font-semibold flex flex-row items-center space-x-1 rounded-t-sm">
                    <div className="w-[5%] truncate invisible lg:visible"><Translate value="App_Header_Label_Status" /></div>
                    <div className="w-1/5 truncate"><Translate value="App_Header_Label_Date" /></div>
                    <div className="w-1/5 truncate"><Translate value="App_Header_Label_Time" /></div>
                    <div className="w-2/5 truncate"><Translate value="App_Header_Label_Comment" /></div>
                    <div className="w-[5%] truncate  invisible lg:visible"><Translate value="App_Header_Label_Edit" /></div>
                    <div className="w-[5%] truncate  invisible lg:visible"><Translate value="App_Header_Label_Copy" /></div>
                    <div className="w-[5%] truncate invisible lg:visible"><Translate value="App_Header_Label_Delete" /></div>
                </div>
                {
                    timeEntries.length > 0 ?
                        (
                            <div className="divide-y divide-gray-dark flex flex-col">
                                {timeEntries?.map((t, i) => <TimeEntry key={t.id} timeEntry={t} onUpdate={updateTimeEntry} refreshTimeRecords={fetchTimeEntriesSilent} />)}
                            </div>
                        )
                        : (isInitialLoadDone && <Divider translatedText={<Translate value="App_Info_No_Booked_Times" timePeriod={getTimePeriod()} />} isWhite />)
                }
            </div>
        </div>
    </>);
}

export default TimeEntryContainer;