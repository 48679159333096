import { Icon } from "@fluentui/react/lib/components/Icon"

interface IIconButton {
    iconName: string,
    disabled?: boolean,
    onClick: Function
}

export const IconButton = (props: IIconButton) => {
    const { iconName, disabled, onClick } = { ...props };

    return (<>
        <button className="lg:inline-flex px-2 lg:text-sm items-center space-x-2 text-primary cursor-pointer my-2 disabled:text-gray-dark" onClick={() => onClick()} disabled={disabled}>
            <Icon iconName={iconName} className='font-light'></Icon>
        </button>
    </>)
}