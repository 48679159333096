import { Icon } from "@fluentui/react/lib/components/Icon/Icon";
import { useEffect, useState } from "react";
import { IAdministration } from "../../Models/IAdministration";
import { PaperClipIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { setHasGlobalNotification, useAppDispatch, useAppSelector, useLazyFetchGlobalNotificationsQuery } from "../../Redux"
import { isMobile } from 'react-device-detect';
import { b64toBlob } from "../../Utils"

export const GlobalNotifications = () => {
    const userProfile = useAppSelector(s => s.userProfile.userProfile);
    const dispatch = useAppDispatch();

    const [notification, setNotification] = useState<IAdministration | undefined>();
    const [showPdf, setShowPdf] = useState<boolean>(false);
    const [fetchGlobalNotifications, { data: globalNotifications, isSuccess }] = useLazyFetchGlobalNotificationsQuery();

    useEffect(() => {
        if (userProfile && userProfile.preferredLanguage && userProfile.preferredLanguage > 0) {
            fetchGlobalNotifications(userProfile.preferredLanguage);
        }
    }, [userProfile, userProfile?.preferredLanguage])

    useEffect(() => {
        if (isSuccess && globalNotifications) {
            let globalNotification = globalNotifications[0];
            setNotification(globalNotification);
            dispatch(setHasGlobalNotification());
        } else {
            setNotification(undefined);
        }
    }, [globalNotifications, isSuccess])

    const openFile = () => {
        if (isMobile) { 
            const fileURL = window.URL.createObjectURL(b64toBlob(notification?.file as string, "application/pdf"));                    
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = notification?.fileName as string;
                alink.click();
        }
        else {
            setShowPdf(true);
        }
    }

    const getMessageText = () => {
        if (notification) {
            const hyperlinkIndex = notification.notificationMessage.indexOf('%hyperlink%');
            if (hyperlinkIndex > -1) {
                return <>
                    <span>{notification.notificationMessage.substring(0, hyperlinkIndex - 1)}&nbsp;</span>
                    <a className="underline" href={`https://${notification.hyperlinkUrl}`} target="_blank">{notification.hyperlinkText}</a>
                    <span>{notification.notificationMessage.substring(hyperlinkIndex + '%hyperlink%'.length + 1, notification.notificationMessage.length - 1)}</span>
                    {
                        notification.fileName && <PaperClipIcon className="ml-2 cursor-pointer h-5 w-5" onClick={openFile} />
                    }
                </>
            } else {
                return <>
                    <span className="flex flex-nowrap flex-row justify-center items-center">
                        <label className="w-[95%] lg:w-full">{notification.notificationMessage}</label>{notification.fileName && <PaperClipIcon className="ml-2 cursor-pointer h-5 w-5" onClick={openFile} />}
                    </span>
                </>
            }
        }
    }

    return (<div className={`top-20 left-0 w-full fixed ${showPdf ? 'z-[110]' : 'z-[105]'}`}>
        {notification &&
            <div className="w-full bg-tertiary/80 bg-gradient-to-r from-tertiary to-coral/50 text-primary text-center flex items-center justify-center mx-0 p-2 text-sm lg:text-base">
                <Icon iconName="Info" className="mr-2" />
                {getMessageText()}
            </div>
        }

        {showPdf &&
            <div className="absolute flex flex-col items-center justify-center w-screen h-screen -top-16 left-0">
                <div className="flex justify-end bg-white h-6 w-full xl:w-[1200px]">
                    <span className="w-5 h-5 cursor-pointer">
                        <XMarkIcon onClick={() => setShowPdf(false)} />
                    </span>
                </div>
                <embed src={`data:application/pdf;base64,${notification?.file}`} className="flex relative w-full xl:w-[1200px] h-screen" />
            </div>
        }
    </div>)
}