import { useSortable } from '@dnd-kit/sortable';
import { IProjectGroup } from '../../Models';
import { DragHandle } from '../Buttons/DragHandle';
import { CSS } from '@dnd-kit/utilities';
import { useDroppable } from '@dnd-kit/core';
import { useEffect, useState } from 'react';
import { ProjectGroupContextualMenu } from './ProjectGroupContextualMenu';
import { updateProjectGroup, useAppDispatch, useAppSelector, useDeleteProjectGroupMutation } from '../../Redux';
import { ProjectGroupEditItem } from '..';

interface IProjectGroupItem {
    projectGroup: IProjectGroup,
    isDroppable?: boolean
}

export const ProjectGroupItem = (props: IProjectGroupItem) => {
    const dispatch = useAppDispatch();

    const projectsList = useAppSelector((state) => state.projects.projectsList);

    const [isContextualMenuHidden, setIsContextualMenuHidden] = useState(true);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [projectCount, setProjectCount] = useState<number>(0);
    const [deleteGroup, { }] = useDeleteProjectGroupMutation();

    const {
        attributes,
        listeners,
        setNodeRef: sortableNodeRef,
        isDragging,
        transform,
        transition,
    } = useSortable({
        id: props.projectGroup.order, transition: {
            duration: 150, // milliseconds
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
        }
    });
    const { setNodeRef: droppableNodeRef, isOver: isDropOver } = useDroppable({ id: `${props.projectGroup.id}&${props.projectGroup.title}` });
    const style = { transform: CSS.Transform.toString(transform), transition };

    useEffect(() => {
        if (projectsList && projectsList.length > 0)
            setProjectCount(projectsList.filter(p => p.projectGroup_Id == props.projectGroup.id).length);
    }, [projectsList])

    const onContextMenuClick = (event: any) => {
        event.preventDefault();
        setIsContextualMenuHidden(!isContextualMenuHidden);
    }

    const handleRenameClick = () => {
        setIsEditMode(true);
        setIsContextualMenuHidden(true);
    }

    const handleDeleteClick = async () => {
        await deleteGroup(props.projectGroup.id);
        var projects = projectsList.filter(p => p.projectGroup_Id == props.projectGroup.id);
        for (var i = 0; i < projects.length; i++) {
            dispatch(updateProjectGroup({ id: projects[i].id as number, groupId: null }));
        }

        setIsContextualMenuHidden(true);
    }

    const handleContextMenuDismiss = () => {
        setIsContextualMenuHidden(true);
    }

    return (<>
        {isEditMode && <ProjectGroupEditItem callback={() => setIsEditMode(false)} projectGroup={props.projectGroup} />}

        {!isEditMode && <div className={`flex flex-wrap border-primary/30 border-[1px] bg-white 
                                        ${props.isDroppable ? 'h-12' : 'h-9'} 
                                        ${isDropOver ? 'shadow-lg border-primary' : 'shadow-none'}`}
            ref={props.isDroppable ? droppableNodeRef : sortableNodeRef} style={style}
            onContextMenu={onContextMenuClick} id={`item-${props.projectGroup.id}`}>
            <div className="flex w-full">
                <div className='w-1/6 flex items-center justify-center bg-primary px-2 text-tertiary select-none'>
                    {/* {props.projectGroup.projectCount} */}
                    {projectCount}
                </div>
                <div className="w-3/4 flex items-center justify-center pl-3 flex-wrap leading-5 select-none truncate" title={props.projectGroup.title}>
                    <p className="text-sm font-semibold text-gray-900 truncate w-full">{props.projectGroup.title}</p>
                </div>
                {!props.isDroppable &&
                    <div className='w-1/12 items-center justify-center flex'>
                        <span {...attributes} {...listeners} className={`${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}><DragHandle /></span>
                    </div>
                }
            </div>
            {!props.isDroppable &&
                <ProjectGroupContextualMenu
                    isHidden={isContextualMenuHidden}
                    onDeleteClick={handleDeleteClick}
                    onRenameClick={handleRenameClick}
                    targetRef={document.getElementById(`item-${props.projectGroup.id}`)}
                    onDismissClick={handleContextMenuDismiss} />
            }
        </div>
        }
    </>)
}