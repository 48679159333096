import { Translate } from "react-i18nify"
import { switchShowMaintenancePanel, useAppDispatch } from "../../Redux"
import { Icon } from "@fluentui/react/lib/components/Icon"

export const MaintenancePanelButton = () => {
    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(switchShowMaintenancePanel());
    }

    return (<>
        <div className="hidden w-12 h-12 lg:h-9 lg:flex lg:w-full lg:text-sm justify-start items-center space-x-2 text-primary cursor-pointer" onClick={onClick}>
            <Icon iconName='CalendarSettings' className='text-primary font-light text-lg'></Icon>
            <span><Translate value='App_Button_Maintenance' /></span>
        </div>
    </>)
}