import { useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react';
import { UserDetailsPersona, Logo, SignOutButton, UserSettingsButton } from '../';
import { LanguageSwitch } from '../Language/LanguageComponent';
import { useOutsideClick } from '../../Utils';
import { useAppSelector } from '../../Redux';

export const RibbonBar = () => {

    const navigate = useNavigate();

    const userProfile = useAppSelector((state) => state.userProfile.userProfile);

    const [showSignOutCallout, setShowSignOutCallout] = useState<boolean>(false);
    const signOutContainerRefDiv = useOutsideClick(() => {
        setShowSignOutCallout(false);
    });

    const goToHome = () => {
        navigate("/dashboard");
    }

    return (<>
        <div className="shadow-md bg-primary h-20 fixed top-0 left-0 z-[100] m-0 w-full flex p-0 items-center">            
            <div className="flex justify-center w-screen cursor-pointer" onClick={goToHome}>
                <Logo />
            </div>
        </div>
        <div className='fixed top-0 right-2 lg:right-0 z-[108] h-20 flex flex-row flex-grow flex-nowrap items-center justify-end space-x-3'>
            <div>
                <LanguageSwitch />
            </div>
            {
                userProfile && <div ref={signOutContainerRefDiv} onClick={() => setShowSignOutCallout(!showSignOutCallout)} className='hidden lg:block'>
                    <UserDetailsPersona />
                    <div className={`${showSignOutCallout ? 'flex' : 'hidden'} fixed top-16 right-20 flex-grow px-5 rounded-md shadow-md bg-white z-[121] hover:ring-1 ring-primary/70 animate-fade-in`}>
                        <div className="flex flex-col w-full">
                            <UserSettingsButton />
                            <SignOutButton />
                        </div>
                    </div>
                </div>
            }
        </div>
    </>
    );
}