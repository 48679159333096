import { translate, Translate } from "react-i18nify";
import { DateTime } from 'luxon'
import { onFormatDate, downloadFile } from '../../Utils'
import { getFileTypeIconProps, initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { DatePicker, DayOfWeek, Icon, Dropdown, IDropdownOption } from '@fluentui/react';
import { useEffect, useState, FormEvent } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useAppDispatch, useAppSelector, useLazyFetchXlsxTimeRecordsQuery, useLazyFetchCsvTimeRecordsQuery, updateLoadingState, useLazyFetchPdfTimeRecordsQuery, useLazyFetchProjectsQuery } from "../../Redux";

initializeFileTypeIcons();
const nowDate = DateTime.now();

export const DownloadTimeEntries = () => {
	const dispatch = useAppDispatch();
    const appInsights = useAppInsightsContext();
	const calendarStrings = useAppSelector((state) => state.calendar.calendarStrings);

	const [fromDate, setFromDate] = useState<Date>(nowDate.set({ day: 1 }).toJSDate());
	const [untilDate, setUntilDate] = useState<Date>(nowDate.set({ day: nowDate.daysInMonth }).toJSDate());
	const [dateRange, setDateRange] = useState<Date[]>([fromDate, untilDate]);
	const [dropdownErrorMesage, setDropdownErrorMesage] = useState<string>('');
	const [projectsOptions, setProjectsOptions] = useState<IDropdownOption[]>([]);
	const [selectedProjects, setSelectedProjects] = useState<number[]>([]);

	const [fetchProjects, { data: projectsList, isSuccess, isError }] = useLazyFetchProjectsQuery();
	const [triggerGetExcel, { data: excelData, isLoading: waitExcelDownload, isSuccess: isExcelQuerySuccess, isError: isExcelQueryError, error: excelError }] = useLazyFetchXlsxTimeRecordsQuery();
	const [triggerGetCsv, { data: csvData, isLoading: waitCsvDownload, isSuccess: isCsvQuerySuccess, isError: isCsvQueryError }] = useLazyFetchCsvTimeRecordsQuery();
	const [triggerGetPdf, { data: pdfData, isLoading: waitPdfDownload, isSuccess: isPdfQuerySuccess, isError: isPdfQueryError }] = useLazyFetchPdfTimeRecordsQuery();

	useEffect(() => {
		setDateRange([fromDate, untilDate]);
	}, [fromDate, untilDate]);

	useEffect(() => {
		fetchProjects(dateRange);
	}, [dateRange]);

	useEffect(() => {
		if (isSuccess && projectsList) {
			let projectOptions: IDropdownOption[] = [];
			projectOptions.push({ key: -1, text: translate('App_Label_Dropdown_Select_All_Projects') });
			projectsList.map(p => ({ key: p.workingPackage.id as number, text: p.displayTitle ? p.displayTitle : p.workingPackage.title})).forEach(item => {
				projectOptions.push(item);
			});
			setProjectsOptions(projectOptions);
		}
	}, [projectsList, isSuccess])

	useEffect(() => {
		if (isExcelQuerySuccess && excelData) {
			dispatch(updateLoadingState(false));
			downloadFile(excelData);
			appInsights.trackEvent({name: "Download excel has been triggered" });
		}

		if (isExcelQueryError) {
			dispatch(updateLoadingState(false));			
		}
	}, [excelData, isExcelQuerySuccess, isExcelQueryError])

	useEffect(() => {
		if (isCsvQuerySuccess && csvData) {
			dispatch(updateLoadingState(false));
			downloadFile(csvData, 'text/csv;encoding:utf-8');
			appInsights.trackEvent({name: "Download csv has been triggered" });
		}

		if (isCsvQueryError) {
			dispatch(updateLoadingState(false));
		}
	}, [csvData, isCsvQuerySuccess, isCsvQueryError])

	useEffect(() => {
		if (isPdfQuerySuccess && pdfData) {
			dispatch(updateLoadingState(false));
			downloadFile(pdfData);
			appInsights.trackEvent({name: "Download pdf has been triggered" });
		}

		if (isPdfQueryError) {
			dispatch(updateLoadingState(false));
		}
	}, [pdfData, isPdfQuerySuccess, isPdfQueryError])

	useEffect(() => {
		if (waitExcelDownload || waitCsvDownload || waitPdfDownload) {
			dispatch(updateLoadingState(true));
		}
	}, [waitExcelDownload, waitCsvDownload, waitPdfDownload])

	const onUntilDateSelected = (date: Date | null | undefined) => {
		if (date && date > fromDate) {
			setUntilDate(date);
		}
	}

	const onDownloadExcelClick = () => {		
		dowloadDocument(triggerGetExcel);
	}

	const onDownloadCsvClick = () => {
		dowloadDocument(triggerGetCsv);
	}

	const onDownloadPdfClick = () => {
		dowloadDocument(triggerGetPdf);
	}

	const dowloadDocument = (downloadDocumentFunction: Function) => {
		if (selectedProjects && selectedProjects.length <= 0) {
			setDropdownErrorMesage(translate('App_Info_Dropdown_Projects_No_Selection'));
		}
		else {
			setDropdownErrorMesage('');
			downloadDocumentFunction({ dateRange: [fromDate, untilDate], projectIds: selectedProjects.filter(key => key !== -1) });
		}
	}

	const onSelectedProjectsChange = (event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any>): void => {
		const { key, selected } = { ...option };
		setDropdownErrorMesage('');
		if (key == -1) {
			setSelectedProjects(selected ? projectsOptions.map(p => p.key as number) : []);
		}
		else {
			let currentSelection = selectedProjects.filter(key => key !== -1);			
			setSelectedProjects(selected ? [...currentSelection, key as number] : currentSelection.filter(t => t !== key));
		}
	}

	return (<>
		<div className="mx-auto w-full h-full lg:w-4/5 lg:shadow-md p-4 mt-5 mb-8">
			<div className="mx-auto flex flex-col w-2/3 space-y-3">
				<div className="w-full">
					<h3> <Translate value='App_Info_Download_Time_Entries' /></h3>
				</div>
				<div className="w-full">
					<div>
						<Dropdown
							id="projects-dropdown"
							placeholder={translate('App_Forms_Dropdown_Projects_Placeholder')}
							label={translate('App_Forms_Dropdown_Projects')}
							multiSelectDelimiter="; "
							required
							onChange={onSelectedProjectsChange}
							multiSelect
							options={projectsOptions}
							selectedKeys={selectedProjects}
							errorMessage={dropdownErrorMesage}
						/>
					</div>
					<div className="w-full mb-3">
						<label className="text-sm font-semibold text-primary truncate after:text-red after:px-1 after:content-['*']"><Translate value='App_Forms_From' /></label>
						<DatePicker isMonthPickerVisible={false}
							placeholder={translate('App_Forms_From')}
							onSelectDate={setFromDate as (date: Date | null | undefined) => void}
							maxDate={untilDate}
							value={fromDate}
							formatDate={onFormatDate}
							firstDayOfWeek={DayOfWeek.Monday}
							strings={calendarStrings}
							underlined />
					</div>
					<div className="w-full mb-3">
						<label className="text-sm font-semibold text-primary truncate after:text-red after:px-1 after:content-['*']"><Translate value='App_Forms_Until' /></label>
						<DatePicker isMonthPickerVisible={false}
							placeholder={translate('App_Forms_Until')}
							onSelectDate={onUntilDateSelected}
							minDate={fromDate}
							value={untilDate}
							formatDate={onFormatDate}
							firstDayOfWeek={DayOfWeek.Monday}
							strings={calendarStrings}
							underlined />
					</div>
				</div>
				<div className="w-full flex flex-row justify-around items-center">
					<button className="flex flex-row items-center justify-center space-x-1 text-sm text-primary cursor-pointer" onClick={onDownloadExcelClick}>
						<Icon {...getFileTypeIconProps({ extension: 'xlsx', size: 24, imageFileType: 'svg' })} />
						<span><Translate value='App_Button_Download' /> (xlsx)</span>
					</button>
					<button className="flex flex-row items-center justify-center space-x-1 text-sm text-primary cursor-pointer" onClick={onDownloadCsvClick}>
						<Icon {...getFileTypeIconProps({ extension: 'csv', size: 24, imageFileType: 'svg' })} />
						<span><Translate value='App_Button_Download' /> (csv)</span>
					</button>
					<button className="flex flex-row items-center justify-center space-x-1 text-sm text-primary cursor-pointer" onClick={onDownloadPdfClick}>
						<Icon {...getFileTypeIconProps({ extension: 'pdf', size: 24, imageFileType: 'svg' })} />
						<span><Translate value='App_Button_Download' /> (pdf)</span>
					</button>
				</div>
			</div>
		</div>
	</>);
}