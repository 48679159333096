import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom'
import { Translate } from 'react-i18nify';
import { Icon } from '@fluentui/react/lib/components/Icon/Icon';
import { useEffect, useState } from 'react';

interface INavigationButtonProps {
    icon: string,
    buttonText: string,
    small?: boolean,
    showCoachMark?: boolean,
    navigationPath: string,
    openInNewWindow?: boolean,
    callback?: Function
}

export const NavigationButton = (props: INavigationButtonProps) => {
    const { icon, buttonText, small, showCoachMark, navigationPath, openInNewWindow, callback, } = { ...props }

    const navigate = useNavigate();
    let resolved = useResolvedPath(navigationPath);
    let isPathMatch = useMatch({ path: resolved.pathname, end: true });
    const [isCoackMarkVisible, setIsCoackMarkVisible] = useState(showCoachMark);

    useEffect(() => {
        setIsCoackMarkVisible(showCoachMark);
    }, [showCoachMark])

    const onLinkClick = () => {
        if (callback) {
            callback();
        }

        if (openInNewWindow) {
            window.open(navigationPath, '_blank');
        }
        else {
            navigate(navigationPath);
        }
    }

    return (<>
        {
            <div className={`relative flex justify-center items-center w-12 h-12 lg:h-9 text-center select-none flex-row space-x-1 lg:w-full lg:text-start text-sm lg:justify-start lg:items-center lg:space-x-2 lg:cursor-pointer transition-all
                            ${small && !isPathMatch? "top-1": "top-0"}
                            ${isPathMatch ? "bg-primary text-white top-0 rounded-t-full lg:hidden" : "text-primary"}`}
                            onClick={onLinkClick}>
                <Icon iconName={icon} className={`text-base lg:text-lg ${isPathMatch ? "font-normal animate-bounce-active" : "font-light"}`}></Icon>
                <span className={`truncate ${small ? 'hidden' : 'flex'} select-none`}><Translate value={buttonText} /></span>
                {
                    isCoackMarkVisible &&
                    <span className="absolute top-0 left-5 lg:relative flex h-3 w-3 items-center">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
                    </span>
                }
            </div>
        }
    </>);
}