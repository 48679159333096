import { useState } from "react";
import { ChangelogNavigationButton, NavigationButton, ShowHiddenProjectsButton, SignOutButton, UserDetailsPersona } from "..";
import { useAppSelector } from "../../Redux";
import { useOutsideClick } from "../../Utils";

export const Footer = () => {
  const userProfile = useAppSelector((state) => state.userProfile.userProfile);
  const hiddenProjectsExist = useAppSelector((state) => state.projects.hiddenProjectsExist);

  const [showProfileOptions, setShowProfileOptions] = useState<boolean>(false);

  const profileCalloutRef = useOutsideClick(() => {
    setShowProfileOptions(false);
  });

  return (<>
    <div className="flex flex-row w-full h-12 bg-white lg:hidden fixed bottom-0 right-0 justify-around items-center pt-1 z-50">
      <NavigationButton buttonText='App_Button_Activity_Templates' icon='Comment' navigationPath='/activityTemplates' small />
      <NavigationButton buttonText='App_Button_Download_Recording' icon='DownloadDocument' navigationPath='/download' small />
      <NavigationButton buttonText='Home' icon='Home' navigationPath='/dashboard' small />
      <ChangelogNavigationButton mobile />
      <div ref={profileCalloutRef}>
        <div onClick={() => setShowProfileOptions(!showProfileOptions)} className='flex justify-center items-center'>
          <UserDetailsPersona />
        </div>
        <div className={`${showProfileOptions ? 'flex' : 'hidden'} fixed bottom-12 flex-grow px-3 rounded-md shadow-md bg-white right-4 animate-fade-in`}>          
          <div className="flex flex-col w-full">
            {hiddenProjectsExist && <ShowHiddenProjectsButton />}
            <NavigationButton
              openInNewWindow
              buttonText='App_Button_Help'
              icon='Help'
              callback={() => setShowProfileOptions(false)}
              navigationPath={userProfile?.preferredLanguage === 1031 ? process.env.REACT_APP_HELP_URL_DE : process.env.REACT_APP_HELP_URL_EN} />
            <SignOutButton />
          </div>
        </div>
      </div>
    </div>
  </>)
}